<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Produkty - seznam</strong>
      </div>
      <action-tools
        :menu-items="feedMenuItems"
        :router-options="routerOptions"
        :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-3">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete nezveřejnit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Nezveřejnit
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmMassEdit(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete upravit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Upravit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="col-md-6">
          <a-tree-select
            style="display:block;"
            :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
            :treeData="treeData"
            placeholder="-- Vyber kategorii --"
            @change="this.changeCategory"
            :value="selectedCategory"
            :loading="item.categories.loading"
          >
          </a-tree-select>
        </div>
        <div class="text-right col-md-3">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.put_id"
        :columns="columns"
        :loading="loading"
        :dataSource="product"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="put_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/ecommerce/product/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <span
          slot="price"
          slot-scope="text"
        >
          <span v-if="searchText">
            <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template> Kč
            </template>
          </span>
          <template v-else>
            <span>{{ text | currency }}</span>
          </template>
        </span>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined || text === null" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <template slot="unit" slot-scope="text">
          <span v-if="text === undefined">
            <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          </span>
          <span v-else>
            [
            <span v-if="text === null">-</span>
            <span v-else-if="searchText">
              <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
              </template>
            </span>
            <template v-else>{{text}}</template>
            ]
          </span>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/ecommerce/product/detail/'+record.put_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.put_id])" okText="Potvrdit" cancelText="Zrušit" v-if="record.put_is_active">
            <template slot="title">
              <p>Opravdu si přejete nezveřejnit položku: #{{ record.put_id }} ?</p>
            </template>
            <a-button icon="close" size="small">Nezveřejnit</a-button>
          </a-popconfirm>
          <a-popconfirm @confirm="confirmActivate([record.put_id])" okText="Potvrdit" cancelText="Zrušit" v-else>
            <template slot="title">
              <p>Opravdu si přejete zveřejnit položku: #{{ record.put_id }} ?</p>
            </template>
            <a-button icon="check" size="small">Zveřejnit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ product.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      feedMenuItems: [
        {
          icon: 'copy',
          title: 'Feed Google',
          func: this.copyGoogle,
        },
        {
          icon: 'copy',
          title: 'Feed Heureka',
          func: this.copyHeureka,
        },
        {
          icon: 'copy',
          title: 'Feed zbozi',
          func: this.copyZbozi,
        },
      ],
      ceyId: this.$route.params.id,
      searchText: '',
      loaded: [],
      loading: false,
      treeData: [],
      dependencies: ['categories'],
      item: {
        categories: {
          loading: false,
        },
      },
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/product/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'put_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.put_id - b.put_id,
          scopedSlots: {
            customRender: 'put_id',
          },
        },
        {
          title: 'Kód',
          dataIndex: 'put_code',
          sorter: (a, b) => {
            return a.put_code + ''.localeCompare(b.put_code + '')
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Název',
          dataIndex: 'history_languages[0].pivot.phe_name',
          sorter: (a, b) => {
            return a.history_languages[0].pivot.phe_name.localeCompare(b.history_languages[0].pivot.phe_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Cena bez DPH',
          dataIndex: 'current_history.phy_price_no_vat',
          sorter: (a, b) => {
            return a.current_history.phy_price_no_vat - b.current_history.phy_price_no_vat
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
        {
          title: 'Zveřejněn',
          dataIndex: 'put_is_active',
          sorter: (a, b) => a.put_is_active - b.put_is_active,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    categories: function () {
      return this.$store.getters['category/currentLanguage']
    },
    selectedCategory: function () {
      return this.$store.getters['category/getActive']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    product: function () {
      return this.$store.getters['product/currentLanguage'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.product.forEach(item => {
                if (item.children !== undefined) {
                  this.selectedRowKeys = this.selectedRowKeys.concat(item.put_id).concat(item.children.map(x => x.put_id))
                } else {
                  this.selectedRowKeys.push(item.put_id)
                }
              })
            } else {
              this.selectedRowKeys = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    copyFeed(file) {
      this.$clipboard(this.$feedPath + file)
      this.$message.success('Odkaz byl zkopírován do schránky.')
    },
    copyGoogle() {
      this.copyFeed(this.$feedGoogle)
    },
    copyHeureka() {
      this.copyFeed(this.$feedHeureka)
    },
    copyZbozi() {
      this.copyFeed(this.$feedZbozi)
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: 'cey' + x.cey_id,
          value: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages.length === 0 ? 'není nastaveno' : x.languages[0].pivot.cle_name),
          children: this.toTreeData(x.all_children),
        }
      })
    },
    changeCategory(key) {
      this.$store.commit('category/SET_ACTIVE', key.toString())
      this.$router.push({ path: `/ecommerce/product/${key}` }).catch(() => {
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    initData() {
      this.dependencies.forEach((value) => {
        this.item[value].loading = true
      })
      Promise.all([
        this.$store.dispatch('category/getList'),
      ])
        .then(() => {
          if (this.categories.length > 0) {
            if (this.ceyId === undefined) {
              if (this.selectedCategory === null) {
                this.changeCategory(this.categories[0].cey_id)
              } else {
                this.$router.push({ path: `/ecommerce/product/${this.selectedCategory}` })
              }
            } else {
              this.changeCategory(this.ceyId)
            }
          }
          if (this.selectedCategory !== null) {
            this.handleRefresh()
          }
        })
        .catch(() => {})
        .finally(() => {
          this.dependencies.forEach((value) => {
            this.item[value].loading = false
          })
          this.loaded.push(this.language)
        })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('product/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmActivate(id) {
      this.loading = true
      this.$store.dispatch('product/activate', id)
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(product) {
      this.loading = true
      this.$store.dispatch('product/deactivate', product)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = product.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !product.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmMassEdit(items) {
      let ids = ''
      items.forEach(x => {
        ids += x + ','
      })
      this.$router.push({ name: 'product.massEdit', query: { products: ids.substr(0, ids.length - 1) } })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.categories.length !== 0) {
      this.treeData = this.toTreeData(this.categories)
    }
    if (this.language !== null) {
      this.initData()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue !== null && !this.loaded.includes(this.language)) {
        this.initData()
      }
    },
    selectedCategory(newValue, oldValue) {
      if (newValue !== null) {
        this.handleRefresh()
      }
    },
    categories(newValue, oldValue) {
      if (newValue !== null && newValue.length > 0) {
        this.treeData = this.toTreeData(this.categories)
      }
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
